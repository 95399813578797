import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../styles/common.scss";
import Menu from "./Layouts/Menu";
import Preloader from "./Preloader";
import ScrollToTop from "./ScrollToTop";
const HomePage = lazy(() => import("../pages/Home"));
const AboutPage = lazy(() => import("../pages/About"));
const CryptoSinglePage = lazy(() => import("../pages/CryptoSingle"));
const EducarePage = lazy(() => import("../pages/Educare"));
const ContactUsPage = lazy(() => import("../pages/ContactUs"));
const MediaPage = lazy(() => import("../pages/Media"));
const PrivacyPolicyPage = lazy(() => import("../pages/PrivacyPolicy"));
const CookiePolicyPage = lazy(() => import("../pages/CookiePolicy"));
const FaqPage = lazy(() => import("../pages/Faq"));
const UnsubscribePage = lazy(() => import("../pages/Unsubscribe"));
const CareerPage = lazy(() => import("../pages/Career"));
const ReferralsPage = lazy(() => import("../pages/Referrals"));
const RegisterPage = lazy(() => import("../pages/Register"));
const MediaDetailPage = lazy(() => import("../pages/MediaDetail"));
const EducareDetailPage = lazy(() => import("../pages/EducareDetail"));
const CareerDetailPage = lazy(() => import("../pages/CareerDetail"));
const LegalPage = lazy(() => import("../pages/Legal"));
const NewsLetterPage = lazy(() => import("../pages/NewsLetter"));
const NewsLetterDetailsPage = lazy(() => import("../pages/NewsLetterDetails"));
const StyleGuidePage = lazy(() => import("../pages/StyleGuide"));
const MembershipPage = lazy(() => import("../pages/Membership"));
const MarketsPage = lazy(() => import("../pages/Markets"));
const GlossaryPage = lazy(() => import("../pages/Glossary"));
const UniqPage = lazy(() => import("../pages/Uniq"));
const SupportedContriesPage = lazy(() => import("../pages/SupportedContries"));
const SupportedContryDetailsPage = lazy(() =>
  import("../pages/SupportedContryDetails")
);

const RiskPage = lazy(() => import("../pages/Risk"));
const CookiesPolicyPage = lazy(() => import("../pages/Cookies"));

const ErrorPage = lazy(() => import("../pages/404"));

const App = () => {
  useEffect(() => {
    setTimeout(() => {
      const allSections = document.querySelectorAll("[data-anim]");

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const element = entry.target;
            element.classList.add("vissible");
          }
        });
      });
      allSections.forEach((section) => {
        observer.observe(section);
      });
    }, 500);
  }, [window.location.pathname]);

  return (
    <Router>
      <Menu />

      <Suspense fallback={<Preloader />}>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={HomePage}></Route>
          <Route exact path="/about" component={AboutPage}></Route>
          <Route exact path="/educare" component={EducarePage}></Route>
          <Route
            path="/educare/:educareSlug"
            component={EducareDetailPage}
          ></Route>
          <Route exact path="/markets" component={MarketsPage}></Route>
          <Route exact path="/contact-us" component={ContactUsPage}></Route>
          <Route exact path="/media" component={MediaPage}></Route>
          <Route exact path="/cookie" component={CookiePolicyPage}></Route>
          <Route exact path="/referrals" component={ReferralsPage}></Route>
          <Route exact path="/signup" component={RegisterPage}></Route>
          <Route exact path="/newsletter" component={NewsLetterPage}></Route>
          <Route
            exact
            path="/newsletter/:newsId"
            component={NewsLetterDetailsPage}
          ></Route>
          <Route exact path="/newsletter" component={NewsLetterPage}></Route>
          <Route exact path="/glossary" component={GlossaryPage}></Route>
          <Route exact path="/buy-uniq-token" component={UniqPage}></Route>

          <Route exact path="/membership" component={MembershipPage}></Route>
          <Route
            path="/media/:slugMedia"
            component={MediaDetailPage}
          ></Route>
          <Route exact path="/careers" component={CareerPage}></Route>
          <Route path="/careers/:id" component={CareerDetailPage}></Route>
          <Route exact path="/toc" component={StyleGuidePage}></Route>
          <Route exact path="/FAQ" component={FaqPage}></Route>
          <Route exact path="/unsubscribe" component={UnsubscribePage}></Route>
          <Route
            exact
            path="/supported-countries"
            component={SupportedContriesPage}
          ></Route>
          <Route
            exact
            path="/supported-countries/:countrysSlug"
            component={SupportedContryDetailsPage}
          ></Route>

          <Route path="/privacy-policy" component={PrivacyPolicyPage}></Route>
          <Route path="/disclosure" component={RiskPage}></Route>

          <Route path="/cookie-policy" component={CookiesPolicyPage}></Route>

          <Route
            exact
            path="/coins/:cryptoSlug"
            component={CryptoSinglePage}
          ></Route>
          <Route path="/404" exact component={ErrorPage}></Route>
          <Route path="/:legalSlug" component={LegalPage}></Route>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default App;
