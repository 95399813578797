import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  legalCategoryData: [],
  categoryStatus: "idle",
  legalData: {},
  selectedLegal: "",
};

export const getLegalCategory = createAsyncThunk(
  "legal/getLegalCategory",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`legal/cat`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getLegalData = createAsyncThunk(
  "legal/getLegalData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`legal/posts?slug=${params.legalSlug}`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const legalSlice = createSlice({
  name: "media",
  initialState,
  reducers: {
    handleSelectedLegalCategory: (state, action) => {
      state.selectedLegal = action.payload;
    },
  },
  extraReducers: {
    [getLegalCategory.pending]: (state, action) => {
      state.categoryStatus = "loading";
      state.message = null;
    },
    [getLegalCategory.fulfilled]: (state, action) => {
      state.categoryStatus = "succeeded";
      state.legalCategoryData = action?.payload?.data?.data;
    },
    [getLegalCategory.rejected]: (state, action) => {
      state.categoryStatus = "failed";
    },
    [getLegalData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getLegalData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.legalData[state.selectedLegal] =
        action?.payload?.data?.data?.details;
    },

    [getLegalData.rejected]: (state, action) => {
      state.status = "failed";
    },
  },
});

export const { handleSelectedLegalCategory } = legalSlice.actions;

export default legalSlice.reducer;
