import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  statusFAQData: "idle",
  error: null,
  message: null,
  faqCategoryData: [],
  faqData: {},
  selectedFaq: "",
};

export const getFaqCategory = createAsyncThunk(
  "faq/getFaqCategory",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`faq-categories`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFaqData = createAsyncThunk(
  "faq/getFaqData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `faq-by-categories?cat_id=${params.categories_id}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {
    handleSelectedFAQCategory: (state, action) => {
      state.selectedFaq = action.payload;
    },
  },
  extraReducers: {
    [getFaqCategory.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getFaqCategory.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.faqCategoryData = action.payload?.data?.data?.details;
    },
    [getFaqCategory.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getFaqData.pending]: (state, action) => {
      state.statusFAQData = "loading";
      state.message = null;
    },
    [getFaqData.fulfilled]: (state, action) => {
      state.statusFAQData = "succeeded";
      state.faqData[state.selectedFaq] = action.payload?.data?.data?.details;
    },
    [getFaqData.rejected]: (state, action) => {
      state.statusFAQData = "failed";
    },
  },
});

export const { handleSelectedFAQCategory } = faqSlice.actions;

export default faqSlice.reducer;
