import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  Homestatus: "idle",
  status: "idle",
  error: null,
  message: null,
  homeData: null,
  appData: null,
  appDataStatus: "idle",
  counterData: null,
  counterStatus: "idle",
};

export const getHome = createAsyncThunk(
  "home/getHome",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`page/home`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getAppDetails = createAsyncThunk(
  "home/getAppDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`app_details`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCounterDetails = createAsyncThunk(
  "home/getCounterDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`counter`);
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const homeSlice = createSlice({
  name: "home",
  initialState,
  reducers: {},
  extraReducers: {
    [getHome.pending]: (state, action) => {
      state.Homestatus = "loading";
      state.message = null;
    },
    [getHome.fulfilled]: (state, action) => {
      state.Homestatus = "succeeded";
      state.homeData = action.payload?.data;
    },
    [getHome.rejected]: (state, action) => {
      state.Homestatus = "failed";
    },

    [getCounterDetails.pending]: (state, action) => {
      state.counterStatus = "loading";
      state.message = null;
    },
    [getCounterDetails.fulfilled]: (state, action) => {
      state.counterStatus = "succeeded";
      state.counterData = action.payload?.data;
    },
    [getCounterDetails.rejected]: (state, action) => {
      state.counterStatus = "failed";
    },

    [getAppDetails.pending]: (state, action) => {
      state.appDataStatus = "loading";
      state.message = null;
    },
    [getAppDetails.fulfilled]: (state, action) => {
      state.appDataStatus = "succeeded";
      state.appData = action.payload?.data;
    },
    [getAppDetails.rejected]: (state, action) => {
      state.appDataStatus = "failed";
    },
  },
});

export default homeSlice.reducer;
