import React, { useEffect, useState } from "react";
import Style from "./Preloader.module.scss";
import Assets from "../Layouts/Assest";
const Preloader = () => {
  const current = window.location.pathname;
  const body = document.querySelector("body");
  const [bg, setBg] = useState();
  useEffect(() => {
    const main = document.querySelector("main");
    if (current == "/") {
      body.style.background = "#543327";
      if (main) {
        main.style.background = "#543327";
      }
      setBg(true);
    } else {
      body.style.background = "#fff";
      if (main) {
        main.style.background = "#fff";
      }
      setBg(false);
    }
  }, [current]);
  return (
    <div className={`${Style.loader} ${bg ? "bg-secondary" : "bg-white"}`}>
      {current == "/" ? <img src={Assets.loader} alt="loader" /> : ""}
    </div>
  );
};

export default Preloader;
