import logo from "../../assets/images/logo.png";
import logo_new from "../../assets/images/logo-new.png";
import loader from "../../assets/images/loader.gif";
import loader2 from "../../assets/images/loader2.gif";

import logo_light from "../../assets/images/logo_light.svg";

import frame1 from "../../assets/images/frames/1.png";
import frame2 from "../../assets/images/frames/2.png";
import frame3 from "../../assets/images/frames/3.png";
import frame4 from "../../assets/images/frames/4.png";

//videos
import home_banner from "../../assets/videos/home_banner.m4v";
import home_banner_responsive from "../../assets/videos/home_banner_responsive.mp4";
import coin from "../../assets/videos/Coin.m4v";
import security from "../../assets/videos/security.mp4";

import Bannermp4 from "../../assets/videos/Banner.mp4";

//shapes
import shape_home_round from "../../assets/images/shapes/shape_home_round.png";
import shape_member_benefits from "../../assets/images/shapes/shape_member_benefits.png";
import shape_platform_home from "../../assets/images/shapes/shape_platform_home.png";
import shape_letterbox from "../../assets/images/shapes/shape_letterbox.png";
import shape_app_intro from "../../assets/images/shapes/shape_app_intro.svg";
import shape_security from "../../assets/images/shapes/shape_security.png";
import shape_u_top from "../../assets/images/shapes/shape_u_top1.svg";
import shape_u_top_light from "../../assets/images/shapes/shape_u_top_light.svg";
import shape_structure_sm from "../../assets/images/shapes/shape_structure_sm.png";
import shape_structure_lg from "../../assets/images/shapes/shape_structure_lg.png";

import shape_coin_lg_bitcoin from "../../assets/images/shapes/shape_coin_lg_bitcoin.png";
import shape_coin_lg_eth from "../../assets/images/shapes/shape_coin_lg_eth.png";

import shape_chart_lg from "../../assets/images/shapes/shape_chart_lg.svg";
import shape_chart_sm from "../../assets/images/shapes/shape_chart_sm.svg";

//icons
import icon_appstore from "../../assets/images/icons/icon_appstore.svg";
import icon_playstore from "../../assets/images/icons/icon_playstore.svg";
import icon_clock from "../../assets/images/icons/icon_clock.svg";
import icon_apple from "../../assets/images/icons/icon_apple.svg";
import icon_play from "../../assets/images/icons/icon_play.svg";
import icon_arrow_contact from "../../assets/images/icons/icon-arrow-contact.svg";
import icon_search from "../../assets/images/icons/icon_search.svg";
import icon_qr from "../../assets/images/icons/icon_qr.svg";

import icon_feature1 from "../../assets/images/icons/icon_feature1.png";
import icon_feature2 from "../../assets/images/icons/icon_feature2.png";
import icon_feature3 from "../../assets/images/icons/icon_feature3.png";
import icon_feature4 from "../../assets/images/icons/icon_feature4.png";
import icon_feature5 from "../../assets/images/icons/icon_feature5.png";
import icon_feature6 from "../../assets/images/icons/icon_feature6.png";
import icon_feature7 from "../../assets/images/contactus/icons/contact_facebook.svg";
import icon_feature8 from "../../assets/images/contactus/icons/contact_twitter.svg";
import icon_feature9 from "../../assets/images/contactus/icons/contact_instagram.svg";
import icon_feature10 from "../../assets/images/contactus/icons/contact_linked_in.svg";
import icon_feature11 from "../../assets/images/contactus/icons/contact_youtube.svg";
import icon_feature12 from "../../assets/images/shapes/first_progress.svg";
import icon_feature13 from "../../assets/images/shapes/second_progress.svg";
import icon_feature14 from "../../assets/images/shapes/third_progress.svg";
import icon_feature15 from "../../assets/images/shapes/fourth_progress.svg";
import icon_feature16 from "../../assets/images/icons/scroll_top.svg";
import icon_feature17 from "../../assets/images/icons/icon_video_play.svg";

//coins
import icon_bitcoin from "../../assets/images/icons/icon_bitcoin.svg";
import icon_lite from "../../assets/images/icons/icon_lite.svg";
import icon_eth from "../../assets/images/icons/icon_eth.svg";
import coin_home from "../../assets/images/home/coin.png";

//icons - benefits
import icon_benefit1 from "../../assets/images/icons/benefit/icon_benefit1.svg";
import icon_benefit2 from "../../assets/images/icons/benefit/icon_benefit2.svg";
import icon_benefit3 from "../../assets/images/icons/benefit/icon_benefit3.svg";
import icon_benefit4 from "../../assets/images/icons/benefit/icon_benefit4.svg";
import icon_benefit5 from "../../assets/images/icons/benefit/icon_benefit5.svg";
import icon_benefit6 from "../../assets/images/icons/benefit/icon_benefit6.svg";
import icon_benefit7 from "../../assets/images/icons/benefit/icon_benefit7.svg";
import icon_benefit8 from "../../assets/images/icons/benefit/icon_benefit8.svg";
import icon_benefit9 from "../../assets/images/icons/benefit/icon_benefit9.svg";
import icon_benefit10 from "../../assets/images/icons/benefit/icon_benefit10.svg";
import icon_benefit11 from "../../assets/images/icons/benefit/icon_benefit11.svg";
//covers
import cover_home_about from "../../assets/images/covers/home_about_cover.png";
import cover_contact_location from "../../assets/images/covers/contact_location_cover.png";
import cover_team from "../../assets/images/covers/cover_team.png";

//about
import about_banner_shadow from "../../assets/images/about/about_banner_shadow.png";
import mission_banner_shadow from "../../assets/images/about/mission-banner-shadow.png";
import about_ecosystem_blocks from "../../assets/images/about/about_ecosystem_blocks.png";
import about_career_shadow from "../../assets/images/about/about_career_shadow.png";
import about_career from "../../assets/images/about/about_career.png";
import about_brand_logo from "../../assets/images/about/unicoin_brand_logo.png";
import about_uniq_logo from "../../assets/images/about/uniq_token.png";
import about_brand_bottom_img from "../../assets/images/about/about_guide_img_bottom.png";
import lisense_certificate from "../../assets/images/about/lisense_certificate.png";
import people_img from "../../assets/images/about/people.png";

//about_mission_icons
import about_mission_blog from "../../assets/images/about/icons/fi_globe.svg";

import app_desktop from "../../assets/images/home/app_desktop.png";
import app_mobile from "../../assets/images/home/app_mobile.png";

//rounds
import round_home_about from "../../assets/images/shapes/round_home_about.png";
import round_features_market from "../../assets/images/shapes/round_features_market.png";
import round_features_market_res from "../../assets/images/shapes/round_features_market_res.png";
import round_home_license from "../../assets/images/shapes/round_home_license.png";
import round_home_trade from "../../assets/images/shapes/round_home_trade.png";
import round_home_security from "../../assets/images/shapes/round_home_security.png";
import round_header from "../../assets/images/shapes/round_header.png";
import round_terms_ref from "../../assets/images/shapes/round_terms_ref.png";
import round_steps_ref from "../../assets/images/shapes/round_steps_ref.png";
import round_detail_career from "../../assets/images/shapes/round_detail_career.png";
import round_detail_newsletter from "../../assets/images/shapes/round_feature_newsletter.png";

//contact
import contact_corner_img from "../../assets/images/contactus/contactus_corner_element.png";

//newsletter
import news_letter_details_banner from "../../assets/images/newsletter/newsletter_details_banner.png";
import news_letter_crypto_update_cover from "../../assets/images/newsletter/newsletter_currency_update_cover.png";
import news_letter_feature1 from "../../assets/images/newsletter/newsletter_feature1.png";
import news_letter_feature2 from "../../assets/images/newsletter/newsletter_feature2.png";
import news_letter_feature3 from "../../assets/images/newsletter/newsletter_feature3.png";
import news_letter_feature4 from "../../assets/images/newsletter/newsletter_feature4.png";
import news_letter_feature5 from "../../assets/images/newsletter/newsletter_feature5.png";
import news_letter_feature6 from "../../assets/images/newsletter/newsletter_feature6.png";
import news_letter_feature7 from "../../assets/images/newsletter/newsletter_feature7.png";
import news_letter_feature9 from "../../assets/images/newsletter/icon/crypto_feature1.png";
import news_letter_feature10 from "../../assets/images/newsletter/icon/crypto_feature2.png";
import news_letter_feature11 from "../../assets/images/newsletter/icon/crypto_feature3.png";
import news_letter_feature12 from "../../assets/images/newsletter/icon/crypto_feature4.png";
import news_letter_feature13 from "../../assets/images/newsletter/icon/crypto_feature5.png";
import news_letter_feature14 from "../../assets/images/newsletter/icon/crypto_feature6.png";
import news_letter_feature15 from "../../assets/images/newsletter/icon/crypto_feature7.png";
import news_letter_feature16 from "../../assets/images/newsletter/icon/crypto_feature8.png";
import news_letter_feature17 from "../../assets/images/newsletter/icon/crypto_feature9.png";
import news_letter_feature18 from "../../assets/images/newsletter/icon/crypto_feature11.png";

//policy
import policy_corner_img from "../../assets/images/policy/policy_background_img.png";

//membership
import membership_cover_img from "../../assets/images/membership/membership_cover.png";
import membership_type_cover from "../../assets/images/membership/membership_type_cover.png";
import card_icon from "../../assets/images/membership/icon/card_icon.svg";
import reward_icon from "../../assets/images/membership/icon/reward_icon.svg";

//glossary
import search_icon from "../../assets/images/glossary/icons/search_icon.svg";
import glossary_banner_cover from "../../assets/images/glossary/glossary_bottom_cover.png";
import modal_logo from "../../assets/images/unicoin-modal-logo.png";

//supportedContries
import contry_feature_1 from "../../assets/images/supportedcontries/icons/contry_feature_1.png";
import contry_feature_2 from "../../assets/images/supportedcontries/icons/contry_feature_2.png";
import contry_feature_3 from "../../assets/images/supportedcontries/icons/contry_feature_3.png";
import contry_feature_4 from "../../assets/images/supportedcontries/icons/contry_feature_4.png";
import contry_feature_5 from "../../assets/images/supportedcontries/icons/contry_feature_5.png";
import contry_utilize from "../../assets/images/supportedcontries/support_contry_details_utilize.png";
import contry_prestigious_cover from "../../assets/images/supportedcontries/presitigious_coin_img.png";
import contry_feature_utilize1 from "../../assets/images/supportedcontries/contry_feature1.png";
import icon_buy from "../../assets/images/supportedcontries/icons/buy.svg";
import icon_sell from "../../assets/images/supportedcontries/icons/sell.svg";

//steps

import screen1 from "../../assets/images/crypto/screen1.png";
import screen2 from "../../assets/images/crypto/screen2.png";
import screen3 from "../../assets/images/crypto/screen3.png";

import app_screen from "../../assets/images/crypto/frame1.png";

import uni_capitals from "../../assets/images/platforms/uni_capitals.png";
import uni_pay from "../../assets/images/platforms/uni_pay.png";
import uni_imfa from "../../assets/images/platforms/uni_imfa.png";
import uni_markets from "../../assets/images/platforms/uni_markets.png";
import tick from "../../assets/images/icons/tick.png";
import home_banner_poster from "../../assets/images/icons/banner.jpg";
import home_security_poster from "../../assets/images/home/security.jpg";


//og:img
import ogImg from "../../assets/images/og-img.png";


const Assets = {
  ogImg,
  Bannermp4,
  tick,
  home_banner_poster,
  home_security_poster,
  coin_home,
  loader,
  loader2,
  logo,
  logo_new,
  logo_light,
  home_banner,
  home_banner_responsive,
  coin,
  security,
  shape_home_round,
  shape_letterbox,
  shape_app_intro,
  icon_appstore,
  icon_playstore,
  icon_qr,
  icon_feature1,
  icon_feature2,
  icon_feature3,
  shape_member_benefits,
  icon_feature4,
  icon_feature5,
  icon_feature6,
  icon_feature7,
  icon_feature8,
  icon_feature9,
  icon_feature10,
  icon_feature11,
  icon_feature12,
  icon_feature13,
  icon_feature14,
  icon_feature15,
  icon_feature16,
  icon_feature17,
  about_banner_shadow,
  about_ecosystem_blocks,
  about_mission_blog,
  mission_banner_shadow,
  about_career_shadow,
  about_career,
  about_brand_logo,
  about_brand_bottom_img,
  about_uniq_logo,
  lisense_certificate,
  icon_apple,
  icon_play,
  cover_home_about,
  cover_team,
  app_desktop,
  app_mobile,
  round_home_about,
  round_home_trade,
  round_home_license,
  round_home_security,

  contact_corner_img,
  policy_corner_img,
  round_terms_ref,
  round_steps_ref,
  news_letter_feature1,
  membership_cover_img,
  card_icon,
  reward_icon,
  membership_type_cover,
  news_letter_feature2,
  news_letter_feature3,
  news_letter_feature4,
  news_letter_feature5,
  news_letter_feature6,
  news_letter_feature7,
  round_detail_career,
  news_letter_details_banner,
  news_letter_crypto_update_cover,
  shape_platform_home,
  round_header,
  cover_contact_location,
  contact_corner_img,
  policy_corner_img,
  round_terms_ref,
  round_steps_ref,
  news_letter_feature1,
  membership_cover_img,
  card_icon,
  reward_icon,
  membership_type_cover,
  news_letter_feature2,
  news_letter_feature3,
  round_detail_career,
  shape_security,
  icon_bitcoin,
  icon_lite,
  icon_eth,
  round_features_market,
  round_features_market_res,
  shape_u_top,
  shape_u_top_light,
  news_letter_feature9,
  news_letter_feature10,
  news_letter_feature11,
  news_letter_feature12,
  news_letter_feature13,
  news_letter_feature14,
  news_letter_feature15,
  news_letter_feature16,
  news_letter_feature17,
  news_letter_feature18,
  round_detail_newsletter,
  search_icon,
  glossary_banner_cover,
  contry_feature_1,
  contry_feature_2,
  contry_feature_3,
  contry_feature_4,
  contry_feature_5,
  icon_search,
  icon_buy,
  icon_sell,
  icon_clock,
  contry_utilize,
  contry_feature_utilize1,
  contry_prestigious_cover,
  icon_arrow_contact,
  icon_benefit1,
  icon_benefit2,
  icon_benefit3,
  icon_benefit4,
  icon_benefit5,
  icon_benefit6,
  icon_benefit7,
  icon_benefit8,
  icon_benefit9,
  icon_benefit10,
  icon_benefit11,
  shape_structure_lg,
  shape_structure_sm,
  screen1,
  screen2,
  screen3,
  app_screen,
  shape_coin_lg_bitcoin,
  shape_coin_lg_eth,

  uni_capitals,
  uni_pay,
  uni_imfa,
  uni_markets,

  shape_chart_lg,
  shape_chart_sm,

  frame1,
  frame2,
  frame4,
  frame3,
  people_img,
  modal_logo,
};
export default Assets;
