import { useDispatch, useSelector } from "react-redux";
import { getMarket } from "../store/slices/marketSlice";
import { getUniqToken } from "../store/slices/uniqTokenSlice";
import { getReferral } from "../store/slices/referralSlice";
import { getCrypto } from "../store/slices/cryptoSlice";
import { useState } from "react";

export const useGetHoverData = () => {
  const dispatch = useDispatch();
  const marketData = useSelector((state) => state.market);
  const uniqTokenData = useSelector((state) => state.uniqToken);
  const referralData = useSelector((state) => state.referral);
  const [bitCoin, setBitCoin] = useState(false);
  const [ethereum, setEthereum] = useState(false);
  const [bitCoinCash, setBitCoinCash] = useState(false);
  const [litecoin, setLitecoin] = useState(false);

  const fetchOnMouseHover = (slug) => {
    switch (slug) {
      case "/markets":
        if (marketData.status === "idle") {
          dispatch(getMarket());
        }
        break;

      case "/coins/buy-bitcoin":
        if (bitCoin == false)
          dispatch(getCrypto({ cryptoSlug: "buy-bitcoin" }));
        setBitCoin(true);
        break;
      case "/coins/buy-ethereum":
        if (ethereum == false)
          dispatch(getCrypto({ cryptoSlug: "buy-ethereum" }));
        setEthereum(true);
        break;
      case "/coins/buy-bitcoin-cash":
        if (bitCoinCash == false)
          dispatch(getCrypto({ cryptoSlug: "buy-bitcoin-cash" }));
        setBitCoinCash(true);
        break;
      case "/coins/buy-litecoin":
        if (litecoin == false)
          dispatch(getCrypto({ cryptoSlug: "buy-litecoin" }));
        setLitecoin(true);
        break;
      case "/uniq":
        if (uniqTokenData.status === "idle") {
          dispatch(getUniqToken());
        }
        break;
      case "/referrals":
        if (referralData.status === "idle") {
          dispatch(getReferral());
        }
        break;
    }
  };

  return {
    fetchOnMouseHover,
  };
};
