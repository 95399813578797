import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  singleStatus: "idle",
  error: null,
  message: null,
  glossaryDetails: null,
  glossarySingle: [],
  glossarySearch: [],
  selectedSlug: "",
  glossary: [],
  glossaryStatus: "idle",
};

export const getGlossaryDetails = createAsyncThunk(
  "glossary/getGlossaryDetails",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`glossary/cat`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const getGlossary = createAsyncThunk(
  "glossary/getGlossary",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api.get(`glossary`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGlossarySingle = createAsyncThunk(
  "glossary/getGlossarySingle",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api.get(
        `glossary/posts?cat=${params.glossarySlug}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getGlossarySearch = createAsyncThunk(
  "glossary/getGlossarySearch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api.get(
        `glossary/posts?cat=${params.glossarySlug}&search_term=${params.searchTerm}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const glossarySlice = createSlice({
  name: "glossary",
  initialState,
  reducers: {
    handleSelectedSlug: (state, action) => {
      state.selectedSlug = action.payload;
    },
  },
  extraReducers: {
    [getGlossaryDetails.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getGlossaryDetails.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.glossaryDetails = action.payload?.data;
    },
    [getGlossaryDetails.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getGlossary.pending]: (state, action) => {
      state.glossaryStatus = "loading";
      state.message = null;
    },
    [getGlossary.fulfilled]: (state, action) => {
      state.glossaryStatus = "succeeded";
      state.glossary = action.payload?.data?.data?.details?.[0]?.posts;
    },
    [getGlossary.rejected]: (state, action) => {
      state.glossaryStatus = "failed";
    },

    [getGlossarySingle.pending]: (state, action) => {
      state.singleStatus = "loading";
      state.message = null;
    },
    [getGlossarySingle.fulfilled]: (state, action) => {
      state.singleStatus = "succeeded";
      state.glossarySingle = action.payload?.data?.data?.details?.[0]?.posts;
    },
    [getGlossarySingle.rejected]: (state, action) => {
      state.singleStatus = "failed";
    },

    [getGlossarySearch.pending]: (state, action) => {
      state.singleStatus = "loading";
      state.message = null;
    },
    [getGlossarySearch.fulfilled]: (state, action) => {
      state.singleStatus = "succeeded";
      state.glossarySingle = action.payload?.data?.data?.details?.[0]?.posts;
    },
    [getGlossarySearch.rejected]: (state, action) => {
      state.singleStatus = "failed";
    },
  },
});

export const { handleSelectedSlug } = glossarySlice.actions;

export default glossarySlice.reducer;
