import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAxiosInstance } from "../../apis/index";

const initialState = {
  status: "idle",
  error: null,
  message: null,
  countriesList: [],
  countryData: {},
  selectedCountry: "",
  singleCountry: null,
};

export const getCountriesList = createAsyncThunk(
  "supportedCountries/getCountriesList",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(`region-category`);

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCountriesData = createAsyncThunk(
  "supportedCountries/getCountriesData",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `supported-countries?id=${params.slugCountry}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSingleCountry = createAsyncThunk(
  "supportedCountries/getSingleCountry",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();
    try {
      const response = await api.get(
        `single-country?slug=${params.singleCountry}`
      );
      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getCountriesSearch = createAsyncThunk(
  "glossary/getCountriesSearch",
  async (params, { rejectWithValue }) => {
    const api = await getAxiosInstance();

    try {
      const response = await api.get(
        `supported-countries?search_term=${params.countrySearch}`
      );

      return response;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const supportCountriesSlice = createSlice({
  name: "supportedCountries",
  initialState,
  reducers: {
    handleSelectedCountry: (state, action) => {
      state.selectedCountry = action.payload;
    },
  },
  extraReducers: {
    [getCountriesList.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCountriesList.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.countriesList = action.payload?.data?.data?.details;
    },
    [getCountriesList.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getCountriesData.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getCountriesData.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.countryData[state.selectedCountry] =
        action.payload?.data?.data?.details;
    },
    [getCountriesData.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getSingleCountry.pending]: (state, action) => {
      state.status = "loading";
      state.message = null;
    },
    [getSingleCountry.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.singleCountry = action.payload?.data;
    },
    [getSingleCountry.rejected]: (state, action) => {
      state.status = "failed";
    },

    [getCountriesSearch.pending]: (state, action) => {
      state.singleStatus = "loading";
      state.message = null;
    },
    [getCountriesSearch.fulfilled]: (state, action) => {
      state.singleStatus = "succeeded";
      state.countryData[state.selectedCountry] =
        action.payload?.data?.data?.details;
    },
    [getCountriesSearch.rejected]: (state, action) => {
      state.singleStatus = "failed";
    },
  },
});

export const { handleSelectedCountry } = supportCountriesSlice.actions;

export default supportCountriesSlice.reducer;
